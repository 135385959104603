import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'pathModifier',
  standalone: true
})
export class PathModifierPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return "";
    }
    if (!value.includes(environment.localPath)) {
      return value;
    }
    return value;
  }

}
